<template>
  <section class="customer-all m-scroll" ref="scroll">
    <!-- 头部导航 -->
    <HeaderBar title="Contact Us"></HeaderBar>
    <div class="customer-box">
        <div class="box-top">
            <img src="@/assets/index/address.png" alt="">
            <span>Contact address</span>
            <span class="copy-address" @click="copy('.copy-address')" data-clipboard-text="11121 Hoser Way Unit 133，Richmond，BC V7A 5G7">Copy</span>
        </div>
        <p>11121 Horseshoe Way Unit 133，Richmond，BC V7A 5G7</p>
        <div v-if="$store.state.isMiniProgram">
            <img style="width:100%;" src="@/assets/map.png" alt="">
        </div>
        <div v-else>
            <iframe class="map-address" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10442.714632097179!2d-123.1000577!3d49.1307378!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485e074a6a7d6cb%3A0x570ed7077384714f!2s11121%20Horseshoe%20Way%20Unit%20133%2C%20Richmond%2C%20BC%20V7A%205G7!5e0!3m2!1sen!2sca!4v1618206576314!5m2!1sen!2sca" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
    
    <div class="customer-box">
        <div class="box-top">
            <img src="@/assets/index/email.png" alt="">
            <span>Contact email</span>
            <span class="copy-email" @click="copy('.copy-email')" data-clipboard-text="customerservice@moobyyoho.com">Copy</span>
        </div>
        <p>customerservice@moobyyoho.com</p>
    </div>
    
    <div class="customer-box">
        <div class="box-top">
            <img src="@/assets/index/wechat.png" alt="">
            <span>Official wechat</span>
            <span class="copy-wechat" @click="copy('.copy-wechat')" data-clipboard-text="moobyyoho_yoho">Copy</span>
        </div>
        <p>mooby_yoho</p>
    </div>

        <div class="customer-box">
        <div class="box-top">
            <img src="@/assets/index/ins.png" alt="">
            <span>Instagram</span>
            <span class="copy-ins" @click="copy('.copy-ins')" data-clipboard-text="Mooby Yoho">Copy</span>
        </div>
        <p>Mooby Yoho</p>
    </div>
    
    <div class="customer-box">
        <div class="box-top">
            <img src="@/assets/index/Facebook.png" alt="">
            <span>Facebook</span>
            <span class="copy-Facebook" @click="copy('.copy-Facebook')" data-clipboard-text="Mooby Yoho">Copy</span>
        </div>
        <p>Mooby Yoho</p>
    </div>
    
    <div class="customer-box">
        <div class="box-top">
            <img src="@/assets/index/clock.png" alt="">
            <span>Working hours</span>
        </div>
        <p><span>Sunday</span>Closed</p>
        <p><span>Monday</span>1p.m. - 6p.m.</p>
        <p><span>Tuesday</span>1p.m. - 6p.m.</p>
        <p><span>Wednesday</span>1p.m. - 6p.m.</p>
        <p><span>Thursday</span>1p.m. - 6p.m.</p>
        <p><span>Friday</span>1p.m. - 6p.m.</p>
        <p><span>Saturday</span>10a.m. - 3p.m.</p>
    </div>
  </section>
</template>

<script>
import HeaderBar from '@/components/zh/m-header.vue'

import Clipboard from 'clipboard'
export default {
  name:'Customer',
  components:{ HeaderBar },
  data(){
    return {
        
    }
  },
  methods:{
    copy(name) {
      var clipboard = new Clipboard(name)
      clipboard.on('success', () => {
        this.$notify({ type: 'success', message: 'Copy Success!' })
      })
        clipboard.on('error', () =>{
        // 不支持复制
        this.$notify({ type: 'warning', message: 'Error, Browser does not support'})
      })
    }
  }
}
</script>

<style lang="less" scoped>
.customer-all {width: 100%;height: 100%;padding-top: 44px;background-color: #F5F9FF;overflow-x: hidden;overflow-y: auto;
padding:44px 16px 20px 16px;
    .customer-box{width:100%;padding:20px 20px 15px 20px;background-color: #fff;margin-top:20px;
        .box-top{border-bottom:1px solid #f1f1f1;
            img{width:24px;height:24px}
            span{font-size: 14px;color:#333;vertical-align: 5px;margin-left:10px;
                &:nth-of-type(2){float: right;color:#2c74da}
            }
        }
        >img{width:100%;margin-top:10px}
        .map-address{width:100%;height:150px;}
        >p{font-size: 14px;line-height: 20px;margin-top:10px;
            span{display: inline-block;width:140px}
        }
    }
}
</style>